<template>
  <div>
    <PatientList
      :patients="patients"
      :totalDocs="totalDocs"
      :page="page"
      @fetchList="getPatientsList"
      @viewPatientDetail="patientDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PatientList from "../../components/patients/List";

export default {
  components: {
    PatientList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      patients: []
    };
  },
  methods: {
    ...mapActions("patient", ["fetchPatients"]),
    async getPatientsList(dataTableParams) {
      let self = this;
      this.fetchPatients(dataTableParams).then(res => {
        self.patients = res.data.data.docs;
        self.totalDocs =
          res.data.data.pagination.length > 0 &&
          res.data.data.pagination[0].total
            ? res.data.data.pagination[0].total
            : 0;
        self.page =
          res.data.data.pagination.length > 0 &&
          res.data.data.pagination[0].page
            ? res.data.data.pagination[0].page
            : 1;
      });
    },
    patientDetail(id) {
      this.$router.push({
        name: "DoctorPatientDetail",
        params: { patientId: id }
      });
    }
  }
};
</script>
